import { mapActions, mapState } from 'vuex';
import FormsMixin from '../FormsMixin';
import ApplicationConfig from './ApplicationConfig';
import { resetApplicationAction } from '../../../vuex/actions';
import { isArray } from '@incodeapps/js-utilities';

export default {

	mixins: [
		FormsMixin
	],

	computed: {

		...mapState({
			applicationSections: state => state.application.sections
		}),

		formsJson() {

			return ApplicationConfig;

		}

	},

	mounted() {

		let status = this.application.data.status;
		console.log(status);

		let notAllowed = [
			'Waiting for Signature',
			'Signatures Received',
			'Documents Received',
			'Complete Booking'
		];
	
		// if (notAllowed.includes(status)) {
		// 	this.showErrorAlert("You can't resubmit an approved application.");
		// 	return this.$router.push({name: 'List'});
		// }
		
		if (this.vModels && this.application.data) {

			this.setDebugValues(this.vModels, this.application.data);
			this.setDefaultValues(this.vModels, this.application.data);

		}

		if (this.$route.params.applicationId == 'new') {
			this.resetApplicationAction()
		}

	},

	methods: {

		...mapActions([
			'saveApplicationAction',
			'resetApplicationAction',
			'submitApplicationAction',
			'saveApplicationSections'
		]),

		async onSubmit() {

			this.submitting = true;
			await this.removeAlerts();

			// if page contains vModels check for errors and save both values and errors to cookie, send to api /save

			if (this.vModels) {

				this.errors = this.validateRequiredFields(this.vModels, true);

				await this.saveApplicationAction({
					data: this.vModels,
					errors: this.errors
				});

				if (Object.keys(this.errors).length && !this.isScanPage) {

					this.errorAlert = this.showErrorAlert(this.$i18n.t('fatalError.errorAppForm'));

					this.submitting = false;

					return;

				}

			}

			// if no errors came up

			this.saveLoading(true);

			// for the steps I use a regex to extract the number in the current step path
			// it was supposed to only increment but it has many exceptions

			let match = this.$route.name.match(/^ApplicationStep(.*?)$/);
			let num = match[1];
			let dashed;
			
			switch (num) {

				case '1':
					num = this.isVendorFromQuebec ? '3.1' : '2';
					break;

				case '2':
					num = '3.1';
					break;

				case '3.3':
					num = '4';
					break;

				case '4':

					if (this.applicationHasCoApplicant) {

						return this.$router.push({
							name: this.isVendorFromQuebec ? 'CoBorrowerStep1' : 'CoBorrowerStep0',
							params: {
								applicationId: this.applicationId
							}
						});

					}

					return this.$router.push({
						name: 'WaitingSubmission',
						params: {
							applicationId: this.applicationId
						}
					});

				// the num should increment to the next step but
				// if it has a dash in it then increment the num after the dash
				default:

					dashed = num.match(/^(\d+)\.(\d+)$/);

					if (!dashed) {

						num = (parseInt(num, 10) + 1).toString();
						break;

					}

					num = dashed[1] + '.' + (parseInt(dashed[2], 10) + 1).toString();

					break;

			}

			return this.$router.push({
				name: `ApplicationStep${num}`,
				params: {
					applicationId: this.applicationId
				}
			});

		},

		async isDobValid(dateString) {

			// Regex pattern for mm/dd/yyyy format
			const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

			if (!pattern.test(dateString)) {
				return false;
			}

			const [month, day, year] = dateString.split("/").map(Number);

			// Check for valid date ranges
			const date = new Date(year, month - 1, day);
			if (
				date.getFullYear() !== year ||
				date.getMonth() !== month - 1 ||
				date.getDate() !== day
			) {
				return false;
			}

			// Check if the person is at least 18 years old
			const today = new Date();
			const eighteenYearsAgo = new Date(
				today.getFullYear() - 18,
				today.getMonth(),
				today.getDate()
			);
			
			const hundredYearsAgo = new Date(
				today.getFullYear() - 100,
				today.getMonth(),
				today.getDate()
			);

			return date <= eighteenYearsAgo && date >= hundredYearsAgo;
		
		},
		
		async isDateValid(dateString) {

			// Regex pattern for mm/dd/yyyy format
			const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

			if (!pattern.test(dateString)) {
				return false;
			}

			const [month, day, year] = dateString.split("/").map(Number);

			// Check for valid date ranges
			const date = new Date(year, month - 1, day);
			if (
				date.getFullYear() !== year ||
				date.getMonth() !== month - 1 ||
				date.getDate() !== day
			) {
				return false;
			}

			return true;
		
		},

		async checkAppDetails(app) {

			console.log('checkAppDetails app', app);

			let isComplete = true;

			if (!this.isAppComplete(app)) {
				console.log('Complete applicant information');
				this.isComplete = false;
				return {
					errorMessage: "Complete applicant information"
				};
			}

			if (
				app.coApps
				&& isArray(app.coApps)
			) {
				
				app.coApps.forEach(c => {
					if (!this.isAppComplete(c)) {
						console.log('Complete co-applicant information');
						isComplete = false;
					}
				});

				if (!isComplete) {
					this.isComplete = false;
					return {
						errorMessage: "Complete co-applicant information"
					};
				}

			}

			if (
				!app.equipments
				|| !isArray(app.equipments)
				|| app.equipments.length < 1
			) {
				console.log('Add equipment');

				isComplete = false;
				this.isComplete = false;
				return {
					errorMessage: "Add at least one equipment"
				}
			}

			this.isComplete = isComplete;

			return isComplete

		},

		isAppComplete(app) {

			console.log('isAppComplete(app) app', app);

			if (
				app == undefined
				|| app.length == 0
			) {
				return false;
			}

			// console.log('isAppComplete ---', app);

			let required = [
				'firstName',
				'lastName',
				'dob',
				'primaryPhone',
				'address1',
				'province',
				'city',
				'postal',
				'titleOwner',
				'employer',
				'employmentTitle',
				'employmentTerm',
			]

			let isComplete = true

			required.forEach(r => {
				// console.log(r);
				console.log(r, app[r]);
				if (
					app[r] == null
					|| app[r].trim() == ''
					|| app[r].trim() == '-'
					|| app[r] == undefined
				) {
					// console.log(r + ' not filled: ', app[r]);
					console.log('--- not complete ---');
					
					isComplete = false
				}
			});

			return isComplete;
			
		},
		
		getProgramType(type) {

			let names = {
				standard: "Standard",
				equalPayment: "Equal Payment",
				buyDown: "Buy Down",
				deferral: "Deferral",
			}

			return names[type];
			
		},

		setDefaults(vModels) {

			this.setDefaultValues(vModels, this.application.data);

		}

	}

};
