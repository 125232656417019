<template>

	<div>
		<!--e-signature-template-->
		<div class="e-signature-template">
			<steps :current="2" :total="4" />

			<div class="container-xl">
				<div class="row">
					<div class="col-24">
						<div class="template__page-title">
							<h1>Bank Verification</h1>
						</div>
						<div class="template__page-description">
							<p>
								Send a bank verification to your customer, or skip to the next step
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="mb-5"></div>

				<form @submit.prevent="onSubmit" novalidate>
					<div class="container-xl template--vh">
						<div class="row">
							<div class="col-lg-12">
								<div class="tffi-card tffi-card-main-card e-signature-card">
									<div class="row">
									<div class="col-lg-12 d-flex align-items-center">
										<p class="my-0">
											Use Bank Verification
										</p>
									</div>
									<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
										<button @click="bankVerification()" class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
											Send
										</button>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="tffi-card tffi-card-main-card signed-contract-card">
								<div class="row">
									<div class="col-lg-12 d-flex align-items-center">
										<p class="my-0">
											Skip Bank Verification
										</p>
									</div>
									<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
										<button @click="goToPage({ name: 'UploadFiles', params: $route.params })" class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
											Skip
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

	</div>

</template>

<script>

	// import ApplicationMixin from '../../application/components/ApplicationForms/Application/ApplicationMixin';
	import FormsMixin from './ApplicationForms/FormsMixin';
	import { mapActions } from 'vuex';
	import axios from 'axios';
	import { getApplication, bankVerification } from '../../api';
	// import store from '../../store';
	// import { saveLoading } from '../../vuex/actions';
	// import dotCase from 'dot-case';
	import { paramCase } from 'change-case';
	// import fs from 'fs';
	const iconv = require('iconv-lite');

	
	axios.defaults.headers.common['Content-Type'] = 'application/json';
	axios.defaults.headers.common['Accept'] = 'application/json';

	export default {

		mixins: [
			// ApplicationMixin
			FormsMixin
		],

		data() {

			return {

			};

		},

		watch: {

		},

		mounted() {

		},

		methods: {

			...mapActions([
				'showErrorAlert',
				'showInfoAlert',
				'removeAlerts'
			]),

			getUrl(id) {

				return {
					name: 'UploadFiles',
					params: {
						status: paramCase(id)
					}
				};

			},

			async getApp() {

				this.saveLoading(true);

				let app = await getApplication(this.$route.params.applicationId);
				console.log('app', app);

				this.saveLoading(false);

			},
			
			async bankVerification() {

				console.log('bankVerification');

				this.saveLoading(true);

				try {
					
					let app = await bankVerification(this.$route.params.applicationId);
	
					console.log('app', app);
	
					this.saveLoading(false);

					this.showSuccessAlert('A request has been sent to Inverite to confirm banking information.')
					
					return this.$router.replace({
						name: 'UploadFiles',
						params: this.$route.params,
						// query: { papFormVoidCheque: false }
					});
					
				} catch (error) {
					console.log('error', error);
					this.saveLoading(false);
					this.showErrorAlert('Something went wrong, try again.');
				}

			},

			navToUploads() {

				return this.$router.push({
					name: 'UploadFiles',
					params: this.$route.params
				});
			
			}

		}

	};

</script>

<style lang="scss" scoped>
.sign-links {
	list-style: none;
	padding-left: 0px;
}
select {
	display: inline!important;
}
</style>