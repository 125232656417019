<template>
	<div class="terms-and-conditions-template">
		<div class="container-lg">
			<div class="row">
				<div class="col-24">
					<div class="terms-and-conditions-template__content">
						<div class="terms-and-conditions-template__content__title">
							<h1>Terms & Conditions</h1>
						</div>
						<div class="terms-and-conditions-template__content__content">
							<p>
								I am submitting personal data regarding other persons or entities. I confirm that I have obtained the consent from all these persons and entities to provide their data. I acknowledge that the persons and entities concerned may be sent an email to confirm accuracy of the data and may object to the processing and transfer.
							</p>
						</div>
						<div class="terms-and-conditions-template__content__consent">
							<input
								v-bind="acceptTerms"
								v-model="vModels['acceptTerms']"
								type="checkbox"
								name="terms-and-conditions-checkbox"
								id="terms-and-conditions-checkbox"
								class="tffi-input-checkbox theming primary-color">
								<label for="terms-and-conditions-checkbox">I acknowledge and confirm the statement above.</label>
						</div>
						<div class="terms-and-conditions-template__content__controls">
							<div class="tffi-navigation-bottom">
								<button type="submit" class="tffi-btn tffi-btn--solid theming secondary-color" :loading="goingBack"
									@click="goToPage({ name: 'ApplicationStep4', params: $route.params })">
									Decline
								</button>
								<span class="flex-grow-1"></span>
								<button class="tffi-btn tffi-btn--solid theming primary-color" type="submit" :loading="goingBack"
									@click="goToSubmission">
									Accept
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>

	import { mapState } from 'vuex';
	import ApplicationMixin from './ApplicationMixin';
	import FormsConfirmationMixin from '../FormsConfirmationMixin';
	import { getApplication } from '../../../../api';


	export default {

		mixins: [
			ApplicationMixin,
			FormsConfirmationMixin
		],

		data() {

			return {
				checkbox: {
					acceptTerms: {
						type: "checkbox",
						required: true,
						label: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
					}
				},
				vModels: {
					acceptTerms: false
				}


			};

		},

		computed: {

		},

		watch: {

		},

		methods: {
			async goToSubmission() {

				this.saveLoading(true);

				if (
					this.vModels.acceptTerms === false
					// || this.vModels.acceptTerms.target === undefined
					// || this.vModels.acceptTerms === false
				) {

					this.errorAlert = await this.showErrorAlert('Please check accept terms checkbox');

				} else if (
					this.vModels.acceptTerms
				) {

					let id = this.$route.params.applicationId

					let app = await getApplication(id)

					let result = await this.saveApplicationAction({
						data: {
							id,
							...app.application,
							usr_CreditReleaseObtained: 'Yes'
						},
						errors: this.errors
					});

					this.goToPage({
						name: 'WaitingSubmission',
						params: {
							applicationId: this.$route.params.applicationId
						}
					})


				}

				this.saveLoading(false);

			}

		}

	};

</script>

<style lang="scss" scoped>
	.edit-btn {
		position: relative;
		top: -1rem;
		right: -1rem
	}

	.card-body {

		.form-group.static-form-group {

			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
			}

		}

		.invalid-feedback {
			margin-top: -1rem;
			margin-bottom: 1rem;
		}

	}

	.form-control-plaintext {
		white-space: pre-line;
	}
</style>
