// don't forget to make sure there is a translation with the same key (titles.[key]) in the component

export default [

	{
		key: 'Dashboard',
		to: {name: 'Dashboard'}
	},

	{
		key: 'NewApplication',
		to: {name: 'ApplicationStep1', params: {applicationId: 'new'}}
	},

	{
		key: 'Contracts',
		children: [

			{
				key: 'Incomplete',
				order: 1,
				to: {name: 'ApplicationList', params: {status: 'incomplete'}}
			},

			{
				key: 'All',
				order: 2,
				to: {name: 'ApplicationList', params: {status: 'all'}}
			},

			{
				key: 'Pending',
				order: 3,
				to: {name: 'ApplicationList', params: {status: 'pending'}}
			},

			{
				key: 'Approved Conditional',
				order: 4,
				to: {name: 'ApplicationList', params: {status: 'Approved Conditional'}}
			},

			{
				key: 'NotApproved',
				order: 5,
				to: {name: 'ApplicationList', params: {status: 'not-approved'}}
			},

			{
				key: 'Kamsel',
				order: 6,
				to: {name: 'ApplicationList', params: {status: 'kamsel'}}
			},

			{
				key: 'MissingDocuments',
				order: 7,
				to: {name: 'ApplicationList', params: {status: 'missing-documents'}}
			},

			{
				key: 'Funded',
				order: 8,
				to: {name: 'ApplicationList', params: {status: 'funded'}}
			},

			{
				key: 'Cancelled',
				order: 9,
				to: {name: 'ApplicationList', params: {status: 'cancelled'}}
			}

		]
	},

	{
		key: 'ChangePassword',
		to: '/change-password',
		meta: {requiresAuth: false}
	},
	
	{
		key: 'AccessibilityPolicy',
		to: '/accessibility-policy',
		meta: {requiresAuth: false}
	},

	{
		key: 'Logout',
		to: '/auth/logout'
	}

];
