<template>
	<div v-if="showScreen" class="guest-applicant-template">
		
		<div class="stepper theming tertiary-color">
			<div class="container-lg px-0">
				<div class="row">
					<div class="col-md-24">
						<div class="d-flex flex-row">

							<div class="stepper__steps flex-grow-1" v-for="(step, index) in steps" :key="index">

								<div v-if="index > 0" class="stepper__steps__line current d-none-">
									<hr>
								</div>

								<div class="stepper__steps__step" :class="'step-' + index">

									<button
										class="stepper__steps__step__icon"
										:class="index + ' ' + (((current + 1) == (index + 1)) ? 'hello- router-link-active' : 'bye-')">
										{{ index + 1 }}
									</button>

									<div class="stepper__steps__step__label">
										<p class="fw-bold">{{ step.label ? step.label : stepLabels[step.num - 1] }}</p>
									</div>

								</div>
								<div v-if="index < (steps.length - 1)" class="stepper__steps__line current">
									<hr>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<template v-if="!submittingApp">
							<h1>Applicant</h1>
						</template>
					</div>
				</div>
			</div>
		</div>

		<form @submit.prevent="onSubmit" novalidate>

			<template v-if="!showConfirmationPage && !submittingApp">
				<div class="container-xl template--vh">
					<div class="row">
						<div class="col-lg-8">
							
							<div class="tffi-card tffi-card-summary-card">
								<div class="tffi-card-summary-card__top-area theming primary-color">
									<!-- <div class="tffi-card-summary-card__top-area__overlay-gradient"></div> -->
									<div class="tffi-card-summary-card__top-area__overlay-image"></div>
									<div class="tffi-card-summary-card__top-area__title-area">
										<h2>{{ programType }} Program</h2>
									</div>
									<div class="tffi-card-summary-card__top-area__financed-amount">
										<p v-if="vModels.program.financedAmount">Financed Amount: <span class="tffi-card-summary-card__top-area__financed-amount__price">${{ vModels.program.financedAmount | formatMoney }}</span></p>
									</div>
								</div>
								<div class="tffi-card-summary-card__bottom-area">
									<div v-if="vModels.program.leasingPeriod" class="tffi-card-summary-card__bottom-area__label">
										<span class="flex-grow-1"></span>
										<p>{{ $t('financingProgram.leasingPeriod') }}: {{ vModels.program.leasingPeriod || 'N/A' }}</p>
									</div>
									<div v-if="vModels.program.rate" class="tffi-card-summary-card__bottom-area__label">
										<p>Rate:</p>
										<span class="flex-grow-1"></span>
										<p>{{ vModels.program.rate }}%</p>
									</div>
									<div v-if="vModels.program.term" class="tffi-card-summary-card__bottom-area__label">
										<p>Finance Term:</p>
										<span class="flex-grow-1"></span>
										<p>{{ vModels.program.term }} Months</p>
									</div>
									<div v-if="vModels.program.amortTerm" class="tffi-card-summary-card__bottom-area__label">
										<p>Amortization Term:</p>
										<span class="flex-grow-1"></span>
										<p>{{ vModels.program.amortTerm }} Months</p>
									</div>
									
									<div v-if="vModels.program.leaseTermMonths" class="tffi-card-summary-card__bottom-area__label">
										<p>Lease Term:</p>
										<span class="flex-grow-1"></span>
										<p>{{ vModels.program.leaseTermMonths }} Month</p>
									</div>
									<div v-if="vModels.program.monthly" class="tffi-card-summary-card__bottom-area__label">
										<p class="theming primary-color fw-bold">Base Monthly Payment:</p>
										<span class="flex-grow-1"></span>
										<p class="theming primary-color fw-bold">${{ vModels.program.monthly | formatMoney }}</p>
									</div>
								</div>
							</div>


							<div v-if="applicant && applicant.firstName" class="tffi-card tffi-card-secondary-card theming primary-color">
								<div class="tffi-card-secondary-card__left-area">
									<!-- <div class="tffi-card-secondary-card__left-area__status-indicator">
										<div class="status-accepted">
											<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
										</div>
										<div class="status-denied">
											<img src="../../../../../assets/img/icons/stop.svg" alt="">
										</div>
									</div> -->
								</div>
								<div class="tffi-card-secondary-card__right-area">
									<div class="tffi-card-secondary-card__right-area__title-area">
										<h2>Applicant</h2>
									</div>
									<div class="tffi-card-secondary-card__right-area__content-area">
										<p>{{ this.applicant['firstName'] + ' ' + this.applicant['lastName'] }}</p>
										<p>{{ this.applicant['email'] }}</p>
									</div>
									<!-- <button @click="removeApplicant(index)" class="tffi-card-secondary-card__right-area__trash-area">
										<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
									</button> -->
								</div>
							</div>
						</div>
						<div class="col-lg-16">

							<div class="tffi-card tffi-card-main-card">
								<div class="row">
									<div class="col-24">
										<h2>{{ this.vModels.firstName + ' ' + (this.vModels.middleInitial ?? '') + ' ' + this.vModels.lastName }}</h2>
									</div>
								</div>
								<div class="row row-cols-lg-2">
									<template v-for="(fieldData, fieldName) in getForm('2')">
										<div :class="fieldData.class">
											<div class="d-flex flex-column">

												<form-field
													v-bind="checkForOptions(fieldName, fieldData)"
													v-model="vModels[fieldName]"
													:name="fieldName"
													:label="getLabel(fieldName)"
													:locale="locale"
													:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
													bootstrap
													@change="onChange"
												></form-field>
											</div>
										</div>
									</template>
									
									<template v-for="(fieldData, fieldName) in getForm('2.0')">
										<div :class="fieldData.class">
											<div class="d-flex flex-column">

												<form-field
													v-bind="checkForOptions(fieldName, fieldData)"
													v-model="vModels[fieldName]"
													:name="fieldName"
													:label="getLabel(fieldName)"
													:locale="locale"
													:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
													bootstrap
													@change="onChange"
												></form-field>
											</div>
										</div>
									</template>

									<template v-for="(fieldData, fieldName) in getForm('2.1')">

										<form-field 
											v-bind="checkForOptions(fieldName, fieldData)" 
											v-model="vModels[fieldName]" 
											:name="fieldName"
											:label="getLabel(fieldName)" 
											:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
											:error="errors[fieldName]" 
											:locale="locale" 
											bootstrap 
											@change="onChange"
										></form-field>
									</template>

								</div>
							</div>

							<div class="container-fluid">
								<div class="row">
									<div class="col-24">
										<div class="template__secondary-title">
											<h2>Employer Details</h2>
										</div>
									</div>
								</div>
							</div>

							<div class="tffi-card tffi-card-main-card">
								<div class="row row-cols-md-2">
									<template v-for="(fieldData, fieldName) in getForm('2.2')">
										<div :class="fieldData.class">
											<div class="d-flex flex-column">
												<form-field 
													v-bind="checkForOptions(fieldName, fieldData)" 
													v-model="vModels[fieldName]" 
													:name="fieldName"
													:label="getLabel(fieldName)" 
													:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
													:error="errors[fieldName]" 
													:locale="locale" 
													bootstrap 
													@change="onChange"
												></form-field>
											</div>
										</div>
									</template>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="tffi-navigation-bottom mb-5">
					<div class="container-xl">
						<div class="row">
							<div class="col order-2 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">
								<button
									type="button"
									class="d-none tffi-btn tffi-btn--solid theming secondary-color"
									color="primary"
									:loading="goingBack"
									@click="goBack({ name: 'ApplicationStep1', params: $route.params })"
								>
									{{ $t('forms.back') }}
								</button>
							</div>
							<div class="col order-1 order-lg-2 col-24 col-lg-8 d-flex justify-content-end">
								<button
									class="tffi-btn tffi-btn--solid theming primary-color me-0"
									:loading="goingBack"
									@click="goToSummary"
									
									>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template v-if="showConfirmationPage && !submittingApp">
				<div class="container-xl template--vh">
					<div class="row">
						<div class="col-lg-8">
							<div class="tffi-card tffi-card-summary-card">
								<div class="tffi-card-summary-card__top-area theming primary-color">
									<div class="tffi-card-summary-card__top-area__overlay-image"></div>
									<div class="tffi-card-summary-card__top-area__title-area">
										<h2>{{ programType }} Program</h2>
									</div>
									<div class="tffi-card-summary-card__top-area__financed-amount">
										<p v-if="vModels.program.financedAmount">Financed Amount: <span class="tffi-card-summary-card__top-area__financed-amount__price">${{ vModels.program.financedAmount | formatMoney }}</span></p>
									</div>
								</div>
								<div class="tffi-card-summary-card__bottom-area">
									<div v-if="vModels.program.leasingPeriod" class="tffi-card-summary-card__bottom-area__label">
										<span class="flex-grow-1"></span>
										<p>{{ $t('financingProgram.leasingPeriod') }}: {{ vModels.program.leasingPeriod || 'N/A' }}</p>
									</div>
									<div v-if="vModels.program.rate" class="tffi-card-summary-card__bottom-area__label">
										<p>Rate:</p>
										<span class="flex-grow-1"></span>
										<p>{{ vModels.program.rate }}%</p>
									</div>
									<div v-if="vModels.program.term" class="tffi-card-summary-card__bottom-area__label">
										<p>Finance Term:</p>
										<span class="flex-grow-1"></span>
										<p>{{ vModels.program.term }} Months</p>
									</div>
									<div v-if="vModels.program.amortTerm" class="tffi-card-summary-card__bottom-area__label">
										<p>Amortization Term:</p>
										<span class="flex-grow-1"></span>
										<p>{{ vModels.program.amortTerm }} Months</p>
									</div>
									<div v-if="vModels.program.monthly" class="tffi-card-summary-card__bottom-area__label">
										<p class="theming primary-color fw-bold">Base Monthly Payment:</p>
										<span class="flex-grow-1"></span>
										<p class="theming primary-color fw-bold">${{ vModels.program.monthly | formatMoney }}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-16">

							<div class="tffi-card tffi-card-review-card mb-5">
								<div class="tffi-card-review-card__review-list__header theming primary-color">
									<p class="theming primary-color">Applicant</p>
									<span class="flex-grow-1"></span>
								</div>
								<ul class="tffi-card-review-card__review-list list-unstyled w-100">
									<template v-for="(fieldData, fieldName) in getForm('1')">

										<li>
											<label>{{ $t('newApplication.' + fieldName) }}:</label>
											<span class="flex-grow-1"></span>
											<p class="tffi-card-review-card__review-list__detail">{{ vModels[fieldName] || '&mdash;' }}</p>
											<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>
										</li>

									</template>

									<template v-for="(fieldData, fieldName) in getForm('2')">

										<li>
											<label>{{ $t('newApplication.' + fieldName) }}:</label>
											<span class="flex-grow-1"></span>
											<p class="tffi-card-review-card__review-list__detail">{{ vModels[fieldName] || '&mdash;' }}</p>
											<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>
										</li>

									</template>
								</ul>

								<div class="tffi-card-review-card__review-list__header theming primary-color">
									<p class="theming primary-color">Address</p>
									<span class="flex-grow-1"></span>
								</div>
								<ul class="tffi-card-review-card__review-list list-unstyled w-100">

									<template v-for="(fieldData, fieldName) in getForm('2.1')">

										<li>
											<label>{{ $t('newApplication.' + fieldName) }}:</label>
											<span class="flex-grow-1"></span>
											<p class="tffi-card-review-card__review-list__detail">{{ vModels[fieldName] || '&mdash;' }}</p>
											<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

										</li>

									</template>
								</ul>

								<div class="tffi-card-review-card__review-list__header theming primary-color">
									<p class="theming primary-color">Employer Details</p>
									<span class="flex-grow-1"></span>
								</div>
								<ul class="tffi-card-review-card__review-list list-unstyled w-100 my-0">
									<template v-for="(fieldData, fieldName) in getForm('2.2')">

										<li>
											<label>{{ $t('newApplication.' + fieldName) }}:</label>
											<span class="flex-grow-1"></span>
											<p class="tffi-card-review-card__review-list__detail">{{ vModels[fieldName] || '&mdash;' }}</p>
											<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

										</li>

									</template>
								</ul>
							</div>

							<div class="container-fluid">
								<div class="row">
									<div class="col-24">
										<div class="template__secondary-title">
											<h2>Terms & Conditions</h2>
											<div class="d-flex align-items-center-">
												<input v-bind="acceptTerms" v-model="vModels['acceptTerms']" type="checkbox"
												name="terms-and-conditions-checkbox" id="terms-and-conditions-checkbox"
												class="tffi-input-checkbox theming primary-color mb-0 me-3">
												<label for="terms-and-conditions-checkbox">
													I agree, acknowledge and represent, that by personally submitting this application TwentyFirst Financial Inc. and/or its affiliates is authorized to obtain my credit report from one or more consumer credit reporting agencies, to verify the information in my credit report with third parties as necessary, and to periodically update my credit information with credit reporting agencies. In addition, I consent to accept, receive, and use in electronic form any loan agreement (and any amendments or renewals), disclosure statements, notices or other associated documents and/ or information that may be delivered to me.
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="tffi-navigation-bottom mb-5">
					<div class="container-xl">
						<div class="row">
							<div class="col order-2 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">
								<button
									type="button"
									class="tffi-btn tffi-btn--solid theming secondary-color"
									color="primary"
									:loading="goingBack"
									@click="showConfirmationPage = false; current = 0"
								>
									{{ $t('forms.back') }}
								</button>
							</div>
							<div class="col order-1 order-lg-2 col-24 col-lg-8 d-flex justify-content-end">
								<button
									class="tffi-btn tffi-btn--solid theming primary-color me-0"
									:loading="goingBack"
									@click="submitApp"
									>
									Submit
								</button>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template v-if="submittingApp">
				<div class="container-md">
					<div class="row">
						<div class="col-24">
							<h2 class="fw-bold text-dark mb-4">
								Thank you! Submission Complete
							</h2>

							<p>
								Your submission has been received and is under review.  If there are any questions, you may be asked to provide additional details.
							</p>
						</div>
					</div>
				</div>
			</template>
		</form>

	</div>
</template>

<script>

// import { mapState } from 'vuex';
import ApplicationMixin from './../../application/components/ApplicationForms/Application/ApplicationMixin.js';
import { getApplication, saveApplication } from '../../api';
import FormsConfirmationMixin from './../../application/components/ApplicationForms/FormsConfirmationMixin.js';
import { mapActions, mapState } from 'vuex';

import { isNumber } from '@incodeapps/js-utilities';


export default {

	mixins: [
		ApplicationMixin,
		FormsConfirmationMixin
	],

	filters: {
		formatMoney(value) {
			return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2,}).format(value);
		},
	},

	data() {

		return {
			showScreen: false,
			showConfirmationPage: false,
			submittingApp: false,
			applicant: {},
			coApps: [],
			programType: '',

			steps: [{
				num: 1,
				name: 'Applicant',
				label: 'Applicant',
			}, {
				num: 2,
				name: 'Review and Submit',
				label: 'Review and Submit',
			}],
			current: 0,
			total: 2,

			selectedApp: {},
			selectedAppIndex: '',
			app: null,
			acceptTerms: false,
			vModels: {
				id: '',
				acceptTerms: '',
				firstName: '',
				middleInitial: '',
				lastName: '',
				sin: '',
				dob: '',
				primaryPhone: '',
				mobilePhone: '',
				country: 'Canada',
				address1: '',
				address2: '',
				province: '',
				city: '',
				postal: '',
				titleOwner: ' ',
				grossIncome: '',
				totalHousingCosts: '',
				totalOtherMonthlyCosts: '',
				program: {}
			},
			required: [
				'dob',
				'primaryPhone',
				'address1',
				'province',
				'city',
				'postal',
				'titleOwner',
				'employer',
				'employmentTitle',
				'grossIncome',
				'employmentTerm'
			]
		};

	},

	watch: {

	},

	created() {

	},

	async mounted() {

		this.saveLoading(true);

		console.log(' this.$route.params', this.$route);
		// return;

		// let referralOther = document.getElementById('referral-other');
		// referralOther.parentElement.style.display = 'none';

		let id = this.$route.params.applicationId
		let token = this.$route.query.token

		this.app = null

		// this.app = await getApplication(id, token)

		let errorMsg = 'Error';

		let res = await getApplication(id, token).catch(async err => {

			console.log('err', err);

			if (err.response && err.response.data && err.response.data.errorMessage) {

				if (err.response.data.errorMessage == 'Invalid token') {
					errorMsg = 'Token expired. Request a new invite from dealer.';
				} else {
					errorMsg = err.response.data.errorMessage;
				}

			} else if (err.message && err.message !== 'Network Error') {

				errorMsg = err.message;

			}

			this.errorAlert = await this.showErrorAlert(errorMsg);

			this.showScreen = false;

			this.saveLoading(false)

		});

		// if (errorMsg) {
		// 	return;
		// }

		this.app = res

		console.log('showScree true');

		if (this.app !== undefined) {
			this.showScreen = true;
		}

		console.log('this.app', this.app);

		this.applicant = {
			firstName: this.app.firstName,
			middleInitial: this.app.middleInitial,
			lastName: this.app.lastName,
			email: this.app.email
		}

		this.coApps = this.app.coApps

		this.vModels = this.app

		// console.log('this.app', this.app);

		this.vModels.program = this.app.program

		console.log('vModels', this.vModels);
		// this.selectedApp = 'main';

		// this.selectApp('main')

		console.log('this.app.coApps', this.app.coApps);

		this.programType = this.vModels.program.program.Code == 2 ? 'Lease' : 'Loan'

		if (
			this.app
			&& this.app.meta
			&& this.app.meta.color1
		) {

			const rootElement = document.documentElement;

			this.app.meta.color1 ? rootElement.style.setProperty('--tffi-primary-color', this.app.meta.color1) : '';
			this.app.meta.color2 ? rootElement.style.setProperty('--tffi-secondary-color', this.app.meta.color2) : '';
			this.app.meta.color3 ? rootElement.style.setProperty('--tffi-tertiary-color', this.app.meta.color3) : '';
			this.app.meta.color4 ? rootElement.style.setProperty('--tffi-accent-color', this.app.meta.color4) : '';
		}

		this.saveLoading(false);

	},

	methods: {

		...mapActions([
			'throwFatalError',
			'submitApplicationAction',
			// 'saveApplicationAction',
			// 'saveValidateAll'
		]),

		getFieldError(name) {

			// return this.application.errors && this.application.errors[name];

		},

		async goToSummary() {

			// this.saveValidateAll(true);
			console.log('this.application.errors', this.application.errors);
			console.log('this.errors', this.errors);
			// return;

			this.saveLoading(true);

			console.log('errors', this.errors);

			// await this.saveApp()

			// if (Object.keys(this.errors).length == 0) {

			// }

			let notFilled = []
			this.required.forEach(element => {

				console.log(element, this.vModels[element]);
				if (
					this.vModels[element] == ''
					|| this.vModels[element] == ' '
					|| this.vModels[element] == '-'
					|| this.vModels[element] == undefined
					|| this.vModels[element] == null
				) {
					notFilled.push(element)
				}

			});

			console.log('notFilled', notFilled);

			if (notFilled.length > 0) {

				let notFilledProper = ''
				notFilled.forEach(element => {
					notFilledProper += this.$i18n.t('newApplication.' + element) + ', '
				});

				await this.showErrorAlert('Please fill in the following required fields: ' + notFilledProper)

			} else {
				this.showConfirmationPage = true
				this.current = 1
			}

			this.saveLoading(false);

		},
		
		async submitApp() {

			this.saveLoading(true);

			this.showConfirmationPage = true;

			console.log('this.vModels.acceptTerms', this.vModels.acceptTerms);

			if (
				this.vModels.acceptTerms === false
				|| this.vModels.acceptTerms=== undefined
				// || this.vModels.acceptTerms === false
			) {

				this.saveLoading(false);

				await this.showErrorAlert('Please check accept terms checkbox');

				return;

			}
			
			await this.saveApp()

			this.submittingApp = true;

			this.saveLoading(false);

		},

		async saveApp() {

			console.log('this.$route.params', this.$route.params);
			let id = this.$route.params.applicationId
			this.vModels.id = id
			console.log('this.vModels', this.vModels);

			// let result = await this.saveApplicationAction({
			// 	id,
			// 	data: {
			// 		...this.vModels,
			// 	},
			// 	// errors: this.errors
			// });

			let application = await saveApplication(this.vModels).catch(err => {

				console.debug('getApplicationData err:', JSON.stringify(err, null, 2));

				if (err.response && err.response.data && err.response.data.errorMessage) {

					// dispatch('showErrorAlert', err.response.data.errorMessage);

				} else if (err.message && err.message !== 'Network Error') {

					// dispatch('showErrorAlert', err.message);

				}

			});

			console.log('application', application);
		},

		onSubmit(e) {

			this.isLoading = true;
			this.submitting = true;

			console.debug(
				'[onSubmit] event:', e,
				'\nvModels:', JSON.stringify(this.vModels, null, 2)
			);

		}
	}

};

</script>

<style lang="scss" scoped>
.float-right {
	float: right;
}
</style>
