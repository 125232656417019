const Dashboard = () => import(/* webpackChunkName: "application" */ '../application/components/Dashboard');
const List = () => import(/* webpackChunkName: "application" */ './components/List');
const Event = () => import(/* webpackChunkName: "application" */ './components/Event');
const PaymentCalculator = () => import(/* webpackChunkName: "application" */ './components/PaymentCalculator');
const LeaseCalculator = () => import(/* webpackChunkName: "application" */ './components/LeaseCalculator');

const Sign = () => import(/* webpackChunkName: "application" */ '../application/components/Sign');
const BankVerification = () => import(/* webpackChunkName: "application" */ '../application/components/BankVerification');
const UploadFiles = () => import(/* webpackChunkName: "application" */ '../application/components/UploadFiles');
const ApplicationDashboard = () => import(/* webpackChunkName: "application-form" */ '../application/components/ApplicationDashboard');

const Step1 = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step1');
const Step2 = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step2');
const Step3 = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step3');
const Step4Confirmation = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step4Confirmation');
const Terms = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Terms');

const WaitingSubmission = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/WaitingSubmission');
const PendingApproval = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/PendingApproval');
const NotApproved = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/NotApproved');
const Approved = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/Approved');
const CreditBureauNotFound = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/CreditBureauNotFound');

export default [

	{
		name: 'Dashboard',
		path: '',
		component: Dashboard,
		meta: {requiresAuth: true}
	},
	
	{
		name: 'PaymentCalculator',
		path: 'payment-calculator/:applicationId?',
		component: PaymentCalculator,
		meta: {requiresAuth: true}
	},
	
	{
		name: 'LeaseCalculator',
		path: 'lease-calculator/',
		component: LeaseCalculator,
		meta: {requiresAuth: true}
	},

	{
		name: 'Sign',
		path: 'sign/:applicationId',
		component: Sign,
		meta: {requiresAuth: true}
	},
	
	{
		name: 'BankVerification',
		path: 'bank-verification/:applicationId',
		component: BankVerification,
		meta: {requiresAuth: true}
	},

	{
		name: 'UploadFiles',
		path: 'upload-files/:applicationId',
		component: UploadFiles,
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationDashboard',
		path: 'application/dashboard/:applicationId?',
		component: ApplicationDashboard,
		meta: {requiresAuth: true}
	},

	{
		name: 'Applications',
		path: 'applications',
		redirect: {name: 'ApplicationList', params: {status: 'all'}},
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationList',
		path: 'applications/:status',
		component: List,
		meta: {requiresAuth: true}
	},

	/*
	 * Application Form
	 */

	{
		name: 'Application',
		path: 'application',
		redirect: 'application/1',
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep1',
		path: 'application/1/:applicationId?',
		component: Step1,
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep2',
		path: 'application/2/:applicationId?',
		component: Step2,
		meta: {requiresAuth: true}
	},

	// {
	// 	name: 'ApplicationStep3',
	// 	path: 'application/3/:applicationId?',
	// 	redirect: 'application/3-1/:applicationId?',
	// 	meta: {requiresAuth: true}
	// },

	{
		name: 'ApplicationStep3',
		path: 'application/3/:applicationId?',
		component: Step3,
		meta: {requiresAuth: true}
	},

	{
		name: 'Terms',
		path: 'application/terms/:applicationId?',
		component: Terms,
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep4',
		path: 'application/4/:applicationId?',
		component: Step4Confirmation,
		meta: {requiresAuth: true}
	},

	/*
	 * Waiting Submit & Status Pages
	 */

	{
		name: 'WaitingSubmission',
		path: 'application/submitting/:applicationId?',
		component: WaitingSubmission,
		meta: {requiresAuth: true}
	},

	{
		name: 'PendingApproval',
		path: 'application/pending/:applicationId?',
		component: PendingApproval,
		meta: {requiresAuth: true}
	},

	{
		name: 'NotApproved',
		path: 'application/not-approved/:applicationId?',
		component: NotApproved,
		meta: {requiresAuth: true}
	},

	{
		name: 'Approved Conditional',
		path: 'application/approved/:applicationId?',
		component: Approved,
		meta: {requiresAuth: true}
	},

	{
		name: 'CreditBureauNotFound',
		path: 'application/credit-bureau-not-found/:applicationId?',
		component: CreditBureauNotFound,
		meta: {requiresAuth: true}
	},

	
	/*
	 * Application Event
	 */

	{
		name: 'ApplicationEvent',
		path: 'application/:event/:applicationId',
		component: Event,
		meta: {requiresAuth: true}
	},

];
