<template>
	<div class="add-applicant-template">
		<steps :current="3" :total="4" />

		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1>Equipment</h1>
					</div>
				</div>
			</div>
		</div>

		<form @submit.prevent="onSubmit" novalidate>
			<div class="container-xl template--vh">
				<div class="row">
					<div class="col-lg-8">
						<program :programType="programType" :financedAmount="vModels.program.financedAmount" :monthly="vModels.program.monthly" />

						<div style="cursor: pointer;" @click="$router.push({ name: 'ApplicationStep2', params: $route.params, query: { select: 'main' } })" v-if="applicant.firstName" class="tffi-card tffi-card-secondary-card theming primary-color person">
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(app.application)" class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(app.application)" class="status-denied">
										<img src="../../../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Applicant</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ this.applicant['firstName'] + ' ' + (this.applicant['middleInitial'] ?? '') + ' ' + this.applicant['lastName'] }}</p>
									<p>{{ this.applicant['email'] }}</p>
								</div>
								<!-- <button @click="removeApplicant(index)" class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button> -->
							</div>
						</div>

						<div style="cursor: pointer;" @click="$router.push({ name: 'ApplicationStep2', params: $route.params, query: { select: index } })"  v-if="coApps" v-for="(coApp, index) in this.coApps" class="tffi-card tffi-card-secondary-card person">
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(coApps[index])" class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(coApps[index])" class="status-denied">
										<img src="../../../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Co-Applicant</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ coApp['firstName'] + ' ' + (coApp['middleInitial'] ?? '') + ' ' + coApp['lastName'] }}</p>
									<p>{{ coApp['email'] }}</p>
								</div>
								<!-- <button @click="removeApplicant(index)" class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button> -->
							</div>
						</div>

						<div v-if="equipments" v-for="(equipment, index) in this.equipments" class="tffi-card tffi-card-secondary-card">
							<div class="tffi-card-secondary-card__left-area">
								<!-- <div class="tffi-card-secondary-card__left-area__status-indicator">
									<div class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div class="status-denied">
										<img src="../../../../../assets/img/icons/stop.svg" alt="">
									</div>
								</div> -->
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Equipment</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>Type: {{ equipment['equipmentType'] }}</p>
									<p>Brand: {{ equipment['brand'] }}</p>
									<p>Model: {{ equipment['model'] }}</p>
								</div>
								<button type="button" @click="removeEquipment(index)" class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button>
							</div>
						</div>

						<div class="template--section-divider"></div>
						<div class="template--section-divider"></div>

					</div>

					
					<div class="col-lg-16">

						<div class="tffi-card tffi-card-main-card" v-if="equipments.length >= 4">
							<div class="row">
								<div class="col-24">
									<p>Maximum number of equipments reached</p>
								</div>
							</div>
						</div>

						<div v-if="equipments.length < 4" class="tffi-card tffi-card-main-card">
							<div class="row row-cols-lg-2">
								<template v-for="(fieldData, fieldName) in getForm('addAsset')">
									<div :class="fieldData.class">
										<div class="d-flex flex-column">
											<form-field
												v-bind="checkForOptions(fieldName, fieldData)"
												v-model="vModels[fieldName]"
												:name="fieldName"
												:label="getLabel(fieldName)"
												:error="errors[fieldName]"
												:locale="locale"
												:mask="getMask(fieldName, fieldData)"
												:enable-only="enableOnly[fieldName]"
												:options="assetTypes"
												bootstrap
												@change="onChange"
											></form-field>
										</div>
									</div>
								</template>
								<div class="col-lg-12 d-inline-flex justify-content-end align-items-center">
									<button
										class="tffi-btn tffi-btn--solid theming accent-color add-equipment-btn px-5"
										type="submit"
										>
										Add
									</button>
								</div>
							</div>
							<!-- <div class="row">
								<div class="col-lg-12 d-flex justify-content-end w-100">
									<button
										class="tffi-btn tffi-btn--solid theming accent-color"
										@click="addEquipment"
										>
										Add
									</button>
								</div>
							</div> -->
						</div>

					</div>
				</div>
			</div>

			<div class="tffi-navigation-bottom">
				<div class="container-xl">
					<div class="row">
						<div class="col order-3 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">
							<button
								type="button"
								class="tffi-btn tffi-btn--solid theming secondary-color"
								color="primary"
								:loading="goingBack"
								@click="goToPage({ name: 'ApplicationStep2', params: $route.params })"
							>
								{{ $t('forms.back') }}
							</button>
						</div>
						<div class="col order-2 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">
							<button
								type="button"
								class="tffi-btn tffi-btn--solid theming secondary-color ms-auto"
								@click="goToPage({ name: 'Dashboard' })"
								>
								Save for later
							</button>
						</div>
						<div class="col order-1 order-lg-3 col-24 col-lg-4 d-flex justify-content-end">
							<button
								class="tffi-btn tffi-btn--solid theming primary-color me-0"
								@click="goToPage({ name: 'ApplicationStep4', params: $route.params })"
								>
								Next
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>

// import { mapState } from 'vuex';
import ApplicationMixin from './ApplicationMixin';
import { saveApplication, getApplication, callAction, getAssetTypes } from '../../../../api';
import { isNumber } from '@incodeapps/js-utilities';


export default {

	mixins: [
		ApplicationMixin
	],

	filters: {
		formatMoney(value) {
			return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2,}).format(value);
		},
	},

	data() {

		return {
			assetTypes: {},
			assetSubTypes: {},
			submitting: false,
			isLoading: true,
			programType: '',

			applicant: {},
			coApps: [],
			equipments: [],
			// selectApp: 0,
			selectedApp: {},
			selectedAppIndex: '',
			app: null,
			vModels: {
				program: {
					financedAmount: ''
				}
			},
			optionals: [
				'mobilePhone',
				'sin',
			]
		};

	},

	watch: {

		'vModels.referral': {

			async handler(val) {

			}

		},

	},

	created() {

	},

	async mounted() {

		this.saveLoading(true);

		let id = this.$route.params.applicationId

		this.app = await getApplication(id)

		console.log('app', this.app.application);

		this.applicant = {
			firstName: this.app.application.firstName,
			middleInitial: this.app.application.middleInitial,
			lastName: this.app.application.lastName,
			email: this.app.application.email
		}

		if (this.app.application.equipments) {
			this.equipments = this.app.application.equipments
		}

		this.vModels.program = this.app.application.program

		console.log('vModels', this.vModels);

		this.coApps = this.app.application.coApps

		
		this.getOptions(this.app.application.program.programId);
		
		this.saveLoading(true);

		console.log('this.app.application.coApps', this.app.application.coApps);

		this.programType = this.vModels.program.program.Code == 2 ? 'Lease' : 'Loan'

		const equipmentInput = document.getElementById('equipment-type');
		equipmentInput.focus();

	},

	methods: {

		async removeEquipment(index) {

			this.saveLoading(true);

			this.equipments.splice(index, 1);

			this.saveApp()

			this.saveLoading(false);

		},

		async addEquipment() {

			console.log('this.vModels.equipmentType', this.vModels.equipmentType);

			if (
				this.vModels.equipmentType == ''
				|| this.vModels.equipmentType == null
				|| !this.assetTypes[this.vModels.equipmentType]['en']
			) {
				console.log('details empty');
				return;
			}

			this.saveLoading(true);

			console.log('addEquipment');

			const equipmentInput = document.getElementById('equipment-type');
			equipmentInput.focus();

			this.saveLoading(true);

			let fields = this.getForm('addAsset');

			console.log('fields', Object.keys(fields));

			console.log('this.equipments before', this.equipments);

			console.log(
				"this.assetTypes[this.vModels.equipmentType]['en']",
				this.assetTypes[this.vModels.equipmentType]['en']
			);

			console.log(
				"this.assetTypes[this.vModels.equipmentType]",
				this.assetTypes[this.vModels.equipmentType]
			);

			console.log('this.vModels.equipmentType', this.vModels.equipmentType);

			this.equipments.push({
				equipmentType: this.assetTypes[this.vModels.equipmentType]['en'],
				brand: this.vModels.brand,
				model: this.vModels.model,
				code: this.vModels.equipmentType
			})

			Object.keys(fields).forEach(element => {

				// console.log(element, this.vModels[element]);
				this.vModels[element] = ''
			});
			// console.log('this.equipments after', this.equipments);

			this.saveApp()

			this.saveLoading(false);

		},

		async getOptions(programId) {

			console.log('getOptions');

			this.saveLoading(true);

			// let programId = 101; // pascalCase(this.$route.params.programId);
			let result = await getAssetTypes(programId || 101).catch(async error => {

				this.errorAlert = await this.showErrorAlert(error);

				this.saveLoading(false);

			});

			console.log(result);

			this.assetTypes = result.assetTypes;
			console.log(' result.assetTypes', result.assetTypes);
			// this.assetSubTypes = result.assetSubTypes;

			console.log(this.assetTypes[Object.keys(this.assetTypes)[0]]['code']);

			if (this.assetTypes) {
				this.vModels.equipmentType = this.assetTypes[Object.keys(this.assetTypes)[0]]['code']
			}

			this.isLoading = false;

			this.saveLoading(false);

		},

		async goToStep(index) {

			console.log('goToStep', index);

			// this.saveApp()

			await this.$router.push({
				name: 'ApplicationStep' + index,
				params: this.$route.params
			});

		},

		async saveApp() {

			this.saveLoading(true);

			console.log('saveApp');

			let result = await this.saveApplicationAction({
				data: {
					id: this.app.application.id,
					equipments: this.equipments
				},
				// errors: this.errors
			});

			console.log('result', result);

			let app = await getApplication(this.app.application.id)

			this.app = null;
			this.app = app;

			console.log('app', this.app);

			console.log('this.selectedApp', this.selectedApp);

			this.saveLoading(false);
		},
		
		onSubmit(e) {

			console.log('onSubmit');

			this.saveLoading(true);
			
			this.addEquipment();

			this.saveLoading(false);

		}

	}

};

</script>

<style lang="scss" scoped>
.tffi-card-secondary-card.person {
	&:hover {
		border: 2px solid #C18F14;
	}
}

</style>
